
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import { Info, LocationList } from '@/types/ordinarySeedling'
import { initWangEdit } from '@/utils/wangEdit'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { MapLocation, UploadFile },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})

export default class OrdinarySeedlingAdd extends Vue {
  private info: Info = {
    projectId: '',
    seedlingType: '',
    plantName: '',
    projectAreaId: '',
    plantDate: '',
    treeAge: '',
    standard: '',
    isFamous: '0',
    locationList: [],
    seedlingIntroduce: '',
    fileList: []
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    seedlingType: [
      { required: true, message: '请选择苗木类型', trigger: 'change' }
    ],
    plantName: [
      { required: true, message: '请输入苗木名称', trigger: 'change' }
    ],
    plantDate: [
      { required: true, message: '请选择栽种日期', trigger: 'change' }
    ],
    locationList: [
      { required: true, message: '请选择位置坐标及数量', trigger: 'change' }
    ],
    fileList: [
      { required: true, message: '请选择苗木图片', trigger: 'change' }
    ],
    seedlingIntroduce: [
      { required: true, message: '请输入苗木介绍', trigger: ['change', 'blur'] }
    ]
  }

  // @Watch('info.projectId')
  // projectIdChange (value: any) {
  //   console.log('选择项目', value)
  //   // this.info.projectAreaId = ''
  // }

  // 苗木类别
  private typeList = []
  // 分区
  private areaList = [] as any

  // 默认展示苗木介绍
  activeName = '0'
  // 富文本实例
  private seedlingIntroduce: any = null
  private locationList: LocationList[] | null = null

  private submitShow = false

  private tableData = []

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  get seedlingId () {
    return this.$route.params.seedlingId
  }

  created () {
    this.getTypeList()
    this.init()
    if (this.seedlingId) {
      this.getDetail()
      this.getSedingInspectionList()
    }
  }

  destroyed () {
    // 销毁编辑器
    this.seedlingIntroduce.destroy()
    this.seedlingIntroduce = null
  }

  // 查询苗木类别列表
  getTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'seedlingType' }).then(res => {
      this.typeList = res.seedlingType || []
    })
  }

  // 获取区域
  getAreaList (id: string) {
    this.$axios.get(this.$apis.project.selectYhProjectAreaByList, {
      projectId: id
    }).then(res => {
      this.areaList = res || []
    })
  }

  getSedingInspectionList () {
    this.$axios.get(this.$apis.seedlingInspection.selectSeedlingCheckRecordHisList, {
      seedlingId: this.$route.params.seedlingId
    }).then(res => {
      this.tableData = res || {}
    })
  }

  selectProject (value: any) {
    this.info.projectAreaId = ''
    this.getAreaList(value) // 获取分区列表
  }

  imgRemove (index: number) {
    this.info.fileList.splice(index, 1)
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.fileList.push(file)
    this.$nextTick(() => {
      (this.$refs.info as ElForm).clearValidate()
    })
  }

  // 富文本初始化
  init () {
    this.$nextTick(() => {
      this.seedlingIntroduce = initWangEdit('#seedlingIntroduce', { placeholder: '请输入植物介绍' })
      this.seedlingIntroduce.create()
      // 动态监听富文本改变
      this.seedlingIntroduce.config.onchange = (html: string) => {
        this.info.seedlingIntroduce = this.seedlingIntroduce.txt.html()
        if (html) {
          ;(this.$refs.info as ElForm).clearValidate()
        }
      }
    })
  }

  // 编辑
  getDetail () {
    this.$axios.get(this.$apis.seedling.selectYhSeedlingBySeedlingId, {
      seedlingId: this.seedlingId
    }).then(res => {
      if (res) {
        // console.log('res', res)
        this.info = res
        this.seedlingIntroduce.txt.html(this.info.seedlingIntroduce || '')
        this.locationList = res.locationList || []
        this.getAreaList(res.projectId) // 获取分区列表
      }
    })
  }

  // 区域详情
  // getAreaDetail (val: any) {
  //   this.$axios.get(this.$apis.project.selectYhProjectAreaByProjectAreaId, {
  //     projectAreaId: val
  //   }).then((res) => {
  //     if (this.map) {
  //       this.map.remove(this.ploygons)
  //       // console.log('res区域', res)
  //       this.ploygons = drawPolygon(this.map, res.locationList, this.polygonCurrentStyle, null)
  //       this.map.setZoom(13)
  //     }
  //   })
  // }

  locationChange (list: LocationList[]) {
    // console.log('locationChange', list)
    this.info.locationList = list
  }

  onSubmit () {
    ;(this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        const url = this.seedlingId ? this.$apis.seedling.updateYhSeedling : this.$apis.seedling.insertYhSeedling
        this.$axios.post(url, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.back()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
